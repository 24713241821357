.rewards_sec {
    width: 100%;
    height: auto;
    background: #18191D;
}

.backArrowhead {
    display: flex;
    align-items: center;
}

.tatalReward h1 {
    color: #FCFCFD;
    font-size: 40px;
    font-weight: 500;
}

.backArrowhead h1 {
    color: #FCFCFD;
    font-size: 40px;
    font-weight: 500;
}

.backArrowhead span {
    font-size: 32px;
    color: #FCFCFD;
}

.tatalReward h2 {
    color: #FCFCFD;
}

.searchhistory {
    display: flex;
    align-items: center;
}

.calanderdrop .dropdown-toggle {
    padding: 5px 40px;
    border: 2px solid #ccc;
    background: transparent;
    border-color: #353945;
    border-radius: 24px;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: aliceblue;
    transition: all .2s;
    line-height: 35px;
    display: flex;
    justify-content: center;
}

.calanderdrop .dropdown-toggle::after {
    display: none;
}

.calenderIcon {
    margin-left: 20px;
    color: #777E90;
}

.calanderdrop .dropdown-toggle:hover {
    background-color: #23262f;
    border-color: #23262f;
    color: #fff;
}

.calanderdrop .dropdown-toggle:active {
    background-color: #23262f !important;
    border-color: #23262f !important;
    color: #fff !important;
}

.boderLine {
    padding-bottom: 30px;
    border-bottom: 1px solid #23262f;
}

.rewardshistoyTable table {
    background: #0000 !important;
    overflow: auto;
    padding: 0;
    white-space: nowrap;
}


.rewardshistoyTable table th {
    color: #777e90;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    background: #17181b;
}

.rewardshistoyTable table tbody tr {
    border: 2px solid;
    border-left: 2px solid #0000;
    border-right: 2px solid transparent;
}

.rewardshistoyTable table tbody tr td {
    background: #17181b;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 15px;
}

.rewardshistoyTable table tbody tr td:first-child {
    color: #777E90;
}

.rewardshistoyTable.referralTable table tbody tr td:last-child {
    color: #3772FF;
}

.calanderdrop .dropdown-toggle.show {
    color: #fcfcfd;
}











@media only screen and (max-width: 1399px) {}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}


@media only screen and (max-width: 768px) {
    .backArrowhead h1 {
        font-size: 22px;
    }

    .searchbox {
        margin-bottom: 20px;
    }
}


@media only screen and (max-width: 767px) {
    .calanderdrop .dropdown-toggle {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .calanderdrop .dropdown-menu.show {
        min-width: 100%;
    }
}

@media only screen and (max-width: 480px) {}

@media only screen and (max-width: 320px) {}