.banner {
    width: 100%;
    height: auto;
}

.bnrtText h1 {
    color: #58BD7D;
    font-size: 48px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.bnrtText h2 {
    font-size: 32px;
    color: #FCFCFD;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
}


.bnrtText p {
    font-size: 16px;
    line-height: 1.5;
    color: #777E90;
}

.bnrtText h6 {
    color: #FCFCFD;
    font-size: 18px;
    font-weight: 400;
}

.bnrImg {
    width: 100%;
    height: 650px;
    object-fit: contain;
}


.faq_sec {
    width: 100%;
    height: auto;
    position: relative;
}

.faqbackImg-block {
    position: relative;
    top: 0px;
    width: 100%;
    height: 200px;
}

.faqbackImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.faqbackImg-block h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.faq_accordion .accordion-button {
    color: #0A0A0A;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: none;
    border: none;
    background: transparent;
}

.faq_accordion .accordion-button:not(.collapsed) {
    background: transparent;
}


.faq_accordion .accordion-button:focus {
    box-shadow: none;
}

.faq_accordion .accordion-item.active {
    border-radius: 10px;
    border: 1px solid rgba(117, 117, 117, 0.20);
    background: #23262F;
    -webkit-backdrop-filter: blur(250px);
    backdrop-filter: blur(250px);
}

.faq_accordion .accordion-item {
    border-radius: 10px;
    /* border: 1px solid rgba(117, 117, 117, 0.20); */
    border: none;
    /* background: #23262F; */
    background: transparent;
    -webkit-backdrop-filter: blur(250px);
    backdrop-filter: blur(250px);
}

/* .faq_accordion .accordion-collapse.collapse.show {
    -webkit-backdrop-filter: blur(250px);
    background-position: center;
    background: #23262F;
    backdrop-filter: blur(250px);
} */

.faq_accordion .accordion-button {
    color: #FCFCFD;
    font-size: 18px;
    font-weight: 400;
}

.faq_sec button.accordion-button.collapsed::after {
    width: 25px;
    height: 25px;
    /* background-image: url(../icons/minus.png) !important; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.faq_sec .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-image: url(/public/assets/icons/minus.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* filter: brightness(0.5); */
}

.faq_sec .accordion-button.active:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-image: url(/public/assets/icons/minus.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: brightness(0.5);
}

.faq_accordion .accordion-item.active .accordion-button:not(.collapsed)::after {
    filter: brightness(0.5);
}

.faq_accordion .accordion-item .accordion-button:not(.collapsed)::after {
    filter: brightness(0.5);
}

.faq_sec .accordion-button::after {
    width: 25px;
    height: 25px;
    background-image: url(/public/assets/icons/add.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.faq_accordion .accordion-item.active .accordion-button {
    color: #FCFCFD;
    font-size: 18px;
    font-weight: 300;
    box-shadow: none;
}

.nubContent {
    display: flex;
}

.numBox {
    position: relative;
    width: 35px;
    height: 35px;
    /* background: #23262f; */
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    color: #FCFCFD;
}

.faq_accordion .accordion-item .accordion-button {
    color: #FCFCFD;
    font-size: 18px;
    font-weight: 300;
    box-shadow: none;
}

.faq_accordion .accordion p {
    color: #777E90;
}



.verified_sec {
    width: 100%;
    height: auto;
    background: #18191d;
}

.verified_sec h1 {
    color: #FCFCFD;
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
}

.verified_sec h1 span {
    color: #58BD7D;
}


footer {
    width: 100%;
    height: auto;
    background: #141416;
    border-top: 1px solid #23262f;
}

.f-logoblock ul li a {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s;
    text-decoration: none;
}

.f-logoblock ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.f-logoblock ul li {
    margin-bottom: 15px;
}

.f-logoblock h4 {
    color: #FCFCFD;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 5px;
}

.f-newslatter h4 {
    color: #FCFCFD;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 5px;
}

.f-newslatter h5 {
    color: #FCFCFD;
    font-size: 15px;
    font-weight: unset;
    margin-bottom: 30px;
}

.f-logoblock ul li p {
    color: #777E90;
}

.subsIcon {
    color: #FCFCFD;
}

.f-logoblock.borderbox {
    position: relative;
}

.f-logoblock.borderbox::before {
    content: '';
    position: absolute;
    top: -48px;
    left: -75px;
    width: 1px;
    height: 320px;
    background: #23262f;
}


.f-logoblock.borderbox::after {
    content: '';
    position: absolute;
    top: -48px;
    left: 250px;
    width: 1px;
    height: 320px;
    background: #23262f;
}

.f-logoblock:first-child {
    display: flex;
}


.f-logoblock.borderbox:first-child {
    display: block !important;
}

.faq_accordion .accordion p a {
    color: #777E90;
}

.faq_sec h1 {
    font-family: 'DM Sans', sans-serif;
}


.footernav .dropdown-toggle {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 60px;
    font-weight: 700;
    transition: all .2s;
    background: transparent;
    padding: 5px 15px;
    color: #FCFCFD;
    border-radius: 24px;
    width: 100%;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 40px;
    border-bottom: 1px solid #23262f;
    border-radius: 0px;
}

.footernav .dropdown-toggle:hover {
    background-color: transparent;
    border-bottom: 1px solid #23262f;
    color: #FCFCFD;
}

.footernav .dropdown-toggle.show.btn {
    border-color: transparent;
    border-bottom: 1px solid #23262f;
    color: #FCFCFD;
    background: transparent;
}

.footernav .dropdown-toggle:active {
    background-color: transparent !important;
    color: #FCFCFD !important;
    border-color: transparent !important;
}

.footernav .dropdown-menu.show {
    display: block;
    min-width: 100%;
    margin-top: 5px;
}

.footernav .dropdown-toggle::after {
    border: none;
}

.footernav .chevronicon {
    font-size: 15px;
    margin-bottom: 2px;
    margin-left: 150px;
}

.footernav .dropdown-toggle::after {
    border: none;
    display: none;
}

.f-navchevIcon {
    font-size: 22px;
}


span.field__view.eye_color {
    top: 12px;

}






















@media (max-width: 1399px) {

    .f-logoblock.borderbox::before {
        content: '';
        position: absolute;
        top: -48px;
        left: -25px;
    }

    .f-logoblock.borderbox::before {
        content: '';
        position: absolute;
        top: -50px;

    }


}

@media (max-width: 1199px) {
    header .navbar-nav .nav-link {
        margin-right: 5px;
        margin-left: 5px;
    }

    .f-logoblock.borderbox {
        display: block;
    }

    .f-logoblock.borderbox::before {
        content: '';
        position: absolute;
        top: -50px;
        left: -23px;
    }





}

@media (max-width: 991px) {
    header .navbar-toggler {
        filter: invert(1);
        border: none;
        padding: 0px;
    }

    header .navbar-toggler:focus {
        box-shadow: none;
        outline: none;
    }

    header .navbar-nav {
        display: flex;
        align-items: flex-start;
        margin: 15px 0px;
    }


    .toggleBox {
        display: flex;
        align-items: center;
    }

    .toggleBox h4 {
        font-weight: 500;
        color: #FCFCFD;
        margin-bottom: 0px;
        font-size: 14px;
        margin-right: 15px;
    }


}

@media (max-width: 768px) {
    header .navbar-toggler {
        filter: invert(1);
        border: none;
        padding: 0px;
    }

    header .navbar-toggler:focus {
        box-shadow: none;
        outline: none;
    }

    .linebox {
        display: none;
    }

    header .navbar-nav {
        display: flex;
        align-items: flex-start;
        margin: 15px 0px;
    }

    .bnrImg {
        width: 100%;
        height: 450px;
        object-fit: contain;
        margin-top: 30px;
    }

    .f-logoblock.borderbox::before {
        display: none;
    }

    .f-logoblock.borderbox::after {
        display: none;
    }

    .f-logoblock:first-child {
        display: block;
    }

    .verified_sec h1 {
        color: #FCFCFD;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
    }

    .faq_accordion .accordion-item {
        border: 1px solid rgba(117, 117, 117, 0.20);
        background: transparent;
        -webkit-backdrop-filter: blur(250px);
        backdrop-filter: blur(250px);
    }

    .accordion-item:not(:first-of-type) {
        border-top: 1px solid !important;
    }



}

@media (max-width: 767px) {
    .numBox {
        display: none;
    }

    .verified_sec {
        display: none;
    }

    .bnrtText h6 {
        display: none;
    }

    .bnrtText h1 {
        font-size: 35px;
    }

    .bnrtText h2 {
        font-size: 25px;
    }

    .faq_accordion .accordion-item .accordion-button {
        font-size: 14px;
        font-weight: 300;
    }







}

@media (max-width: 480px) {}

@media (max-width: 390px) {
    .faq_sec h1 {
        font-size: 20px !important;
    }
}

@media (max-width: 320px) {}




#dynamitable {
    /* background: aquamarine; */
    padding: 10px;
}

#dynamitable table.table.coinlist {
    background: aquamarine !important;
}

#dynamitable .table {

    background-color: #23262f !important;
}

#dynamitabl table.table.coinlist tr td {
    color: #FCFCFD;
}