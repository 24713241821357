header {
    height: auto;
    /* padding: 10px 0; */
    padding: 0px 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    background: #141416;
}

.mainLogo {
    width: 150px;
}

.linebox {
    width: 1px;
    height: 30px;
    background: #23262f;
    margin-right: 15px;
    margin-left: 15px;
}

header .navbar-nav .nav-link {
    color: #777E90;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-right: 15px;
    margin-left: 15px;
    position: relative;
    transition: all .2s ease;
    font-family: 'DM Sans', sans-serif;
}

header .navbar-nav .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 0px;
    height: 2px;
    background: #3772ff;
    transition: all .8s ease;
}

header .navbar-nav .nav-link:hover::after {
    width: 100%;
}

header .navbar-nav .nav-link:hover {
    color: #fff;
}

.headdropdown .dropdown-toggle {
    background-color: transparent;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 40px;
    font-weight: 700;
    transition: all .2s;
    padding: 0px;
    color: #777E90;
}

.headdropdown .dropdown-toggle:hover {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}

.headdropdown .dropdown-toggle.show.btn {
    border-color: transparent;
    background-color: transparent;
    color: #777E90;
}

.headdropdown .dropdown-toggle:active {
    background-color: transparent !important;
    color: #fff !important;
    border-color: transparent !important;
}



.headdropdown .dropdown-toggle::after {
    border: none;
}

.headdropdown .chevronicon {
    font-size: 15px;
    margin-bottom: 2px;
    margin-left: 10px;
}


.dashboardheader .dropdown-toggle {
    white-space: nowrap;
    border: none;
}

.headbtnbox .btn.btn-custombtn1 a {
    color: #fff;
    text-decoration: none;
}

.headbtnbox .btn.btn-custombtn2 a {
    text-decoration: none;
    color: #fff;
}

.headbtnbox .btn.btn-custombtn2:hover a {
    color: #000;
}

.headdropdown.profileDrop {
    position: relative;
}

.headdropdown.profileDrop .dropdown-menu.show {
    position: absolute;
    left: -120px;
    top: 60px;
}

.headprofile {
    width: 40px;
    object-fit: contain;
}

.headbtnbox a {
    text-decoration: none;
}










@media only screen and (max-width: 1024px) {
    .headbtnbox .btn.btn-custombtn1 {
        font-size: 12px;
    }

    .headbtnbox .btn.btn-custombtn2 {
        font-size: 12px;
    }
}


@media only screen and (max-width: 768px) {
    .languaDiv {
        display: flex;
        align-items: center;
        width: max-content;
        justify-content: space-between;
    }

    .mobLink.navbar-nav .nav-link {
        color: #777e90;
        font-weight: 500;
    }

}

@media only screen and (max-width: 767px) {
    .languaDiv {
        display: flex;
        align-items: center;
        width: max-content;
        justify-content: space-between;
    }

    .mobLink.navbar-nav .nav-link {
        color: #777e90;
        font-weight: 500;
    }

}