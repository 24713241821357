.ticket_sec {
    width: 100%;
    height: auto;
}

.addIcons {
    font-size: 25px;
    margin-right: 5px;
}

.rewardshistoyTable {
    background: #17181b;
    border: #f0f8ff;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
}

.attachFile a {
    color: #3772ff;
    text-decoration: none;
}

.attachFile a span {
    margin-right: 5px;
}







@media only screen and (max-width: 767px) {

    .backArrowhead h1 {
        font-size: 18px;
    }

    .btn.btn-custombtn1 {
        font-size: 14px;
    }

    .rewardshistoyTable {
        padding: 0px;
    }


}