.mainExchange {
    width: 100%;
}

.searchbox {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background: none;
    border: 2px solid #26282E;
    font-size: 12px;
    color: #777E90;
    padding: 5px 10px;
}

.searchbox input {
    width: 100%;
    border-radius: 8px;
    background: none;
    border: none;
    font-size: 12px;
    color: #777E90;
}

.searchbox input::placeholder {
    color: #777E90;
}

.searchbox input:focus {
    box-shadow: none;
    background: none;
    border-color: #ccc;
    color: #777E90;
}

.searchIcon {
    font-size: 18px;
}

.assetsBox {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background: none;
    border: 1px solid #434141;
    font-size: 12px;
    color: #777E90;
    padding: 5px 10px;
}

.assetsBox select {
    width: 100%;
    border-radius: 8px;
    background: none;
    border: none;
    font-size: 12px;
    color: #777E90;
}

.assetsBox select:focus {
    box-shadow: none;
    background: none;
    border-color: #ccc;
    color: #777E90;
}

.dropIcon {
    font-size: 18px;
}

.pairtabsBox {
    /* border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #fff; */
}

.pair_tabs {
    display: flex;
    justify-content: space-between;
}

.pair_tabs .nav-item button.active {
    background: #0000;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 2px solid #55bd6c;
    font-size: 11px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777E90;
    border-radius: 0px;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
}

.pair_tabs .nav-item button {
    background: #0000;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 2px solid transparent;
    font-size: 11px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777E90;
    border-radius: 0px;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
}


.pair_tabs .nav-item button:hover {
    border-color: #434141;
}

.pairtable .table {
    margin-top: 4px;
    padding: 16px;
    border-radius: 4px;
    background: transparent;
}

table.table.pairtable {
    margin-top: 4px;
    padding: 16px;
    border-radius: 4px;
    background: transparent !important;
}

.pairtableBox {
    width: 100%;
    border: aliceblue;
    background: #17181B;
    border-radius: 4px;
    padding: 15px;
}

.pairtableBox table {
    background: transparent !important;
    padding: 0px;
    overflow: auto;
    white-space: nowrap;
}

.pairtableBox table th {
    font-size: 12px;
    font-weight: 600;
    color: #777E90;

    text-transform: uppercase;
}

.pairtableBox table thead th {
    background: #17181b;
}

.pairtableBox table tbody tr td {
    background: #17181b;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
}

.pairtableBox table tbody tr td span {
    font-size: 12px;
    font-weight: 600;
}

.inrcoindeatils {
    display: flex;
    align-items: center;
}

.coinIcons {
    width: 25px;
}

.marketname {
    color: #fff;
    font-size: 12px;
}

.marketname span {
    color: #777E90;
    font-size: 12px;
}

.downIcon {
    font-size: 20px;
}

.pairtableBox table td:last-child {
    text-align: right;
}

.pairtableBox table th:last-child {
    text-align: right;
}

.pairtableBox table tbody tr.active {
    border: 2px solid;
    border-left-color: #6e727c;
}

.pairtableBox table tbody tr:hover {
    cursor: pointer;
}

.pairtableBox table tbody tr td.active {
    border-right: none;
    border-top: none;
    border-bottom: none;
}

.pairtableBox table tbody tr {
    border: 2px solid;
    border-right-color: transparent;
    border-left-color: transparent;
}

.pairtableBox table tbody tr:hover {
    border: 2px solid;
    border-left-color: #6e727c;
}

.pairtableBox table tbody tr:hover td {
    background: #202020;
}

/* .pairtableBox table tbody tr td {
    border: 2px solid;
    border-left-color: aquamarine;
}

.pairtableBox table tbody tr td {
    border: none;
} */

.ChartImg {
    height: 600px;
    width: 100%;
    object-fit: fill;
    min-height: 100%;
    opacity: 0;
}

.checkswitch label {
    color: #777E90;
    font-size: 14px;
}

.checkswitch input:focus {
    box-shadow: none;
}



.buyselltabsBox ul {
    /* display: flex; */
    display: none;
}

.buyselltabsBox ul li {
    width: 50%;
}



.buysell_tabs .nav-item button.active {
    background: #232324;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 2px solid #55bd6c;
    font-size: 11px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777E90;
    border-radius: 0px;
    text-transform: uppercase;
    width: 100%;
}

.buysell_tabs .nav-item button {
    background: #0000;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 2px solid #f6685e;
    font-size: 11px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777E90;
    border-radius: 0px;
    text-transform: uppercase;
    width: 100%;
}

/* .buysell_tabs .nav-item button:hover {
    border-color: #434141;
} */

.buysellbtnBox .btn.btn-custombtn2 a {
    font-size: 12px !important;
}

.buysellbtnBox .btn.btn-custombtn1 a {
    font-size: 12px !important;
}

.downarrowIcon {
    font-size: 18px;
}


.order_tabs {
    display: flex;
}

.order_tabs .nav-item {
    margin-right: 10px;
}

.order_tabs .nav-item button {
    background: #0000;
    color: #777E90;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.66667;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    border-color: transparent;
}

.order_tabs .nav-item button.active {
    background: #353945;
    font-size: 14px;
    line-height: 1.66667;
    font-weight: 600;
    color: #FCFCFD;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    border-color: transparent;

}

.order_tabs .nav-item button:hover {
    border-color: #434141;
}

.buyselltabsBox {
    width: 100%;
    border: aliceblue;
    background: #17181B;
    border-radius: 4px;
    padding: 15px;
    height: 440px;
}

.pairtableBox.assetstable {
    height: 550px;
    overflow: auto;
}


.limitmarket_tabs {
    display: flex;
    justify-content: space-between;
}

/* .limitmarket_tabs .nav-item {
    margin-right: 10px;
} */

.limitmarket_tabs .nav-item button {
    background: #23262F;
    color: #777E90;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.66667;
    font-weight: 600;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 50px;
    border-color: #23262F;
    font-family: 'DM Sans', sans-serif;
}

.limitmarket_tabs .nav-item button.active {
    background: #FCFCFD;
    font-size: 14px;
    line-height: 1.66667;
    font-weight: 600;
    color: #23262F;
    text-transform: uppercase;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 50px;
    border-color: #FCFCFD;
    font-family: 'DM Sans', sans-serif;
}

.limitmarket_tabs .nav-item button:hover {
    border-color: #434141;
}

/* .field.limitfield button {
    font-weight: 500;
    color: #777E90;
} */

.availabelhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.availabelhead h6 {
    color: #E6E8EC;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins';
}

.limitfieldBox.input-group {
    border-radius: 12px;
    border: 1px solid #23262f;
    box-shadow: none;
    background: none;
    height: 48px;
}


.limitfieldBox.input-group input {
    border-radius: 12px;
    border: 1px solid #23262f;
    box-shadow: none;
    background: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500;
    color: #777E97;
    transition: border-color .2s;
}

.limitfieldBox.input-group .input-group-text {
    color: #777E97;
    border-radius: 12px;
    border: 1px solid #23262f;
    box-shadow: none;
    background: none;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500;
}

.limitfieldBox.input-group input::placeholder {
    color: #777E90;
}


.bargaining__field.limitfieldBox {
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: #777E97;
    border-radius: 12px;
    border: 1px solid #23262f;
    box-shadow: none;
    background: none;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500;
}

.bargaining__field.limitfieldBox input {
    border: none;
    width: 100%;
}

.walletIcons {
    font-size: 20px;
    margin-right: 5px;
}

.pairtableBox.tradehistorydiv {
    height: auto;
    overflow: auto;
    max-height: 450px;
}

ul.limitmarket_tabs {
    border-bottom: 1px solid #23262f;
    /* padding-bottom: 25px; */
}

.pairtabsBox.limitDiv {
    border-bottom: 1px solid #23262f;
    padding-bottom: 20px;
}

.loarderBox {
    width: 100%;
    height: 485px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderbtn.btn.btn-primary {
    background: transparent;
    border: none;
    font-size: 45px;
}

.loaderbtn.btn.btn-primary span {
    font-size: 55px;
    margin-right: 10px;
}


.pairDropdown {
    position: relative;
}

.pairDropdown button {
    background: #495a82;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #4e6eb5;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.pairDropdown button::after {
    display: none;
}

.pairDropdown button:hover {
    background: #495a82;
    border: 2px solid #4e6eb5;
}

.pairDropdown button:active {
    background: #495a82 !important;
    border: 2px solid #4e6eb5 !important;
}

.pairDropdown .dropdown-item {
    padding: 0px;
    margin: 0px;
    background: transparent;
}

/* .pairDropdown .dropdown-menu.show {
    display: block;
    margin: 0px;
    padding: 10px;
    background: #17181b;
    width: 100%;
} */

.pairDropdown .dropdown-menu.show {
    display: block;
    margin: 0px;
    padding: 10px;
    /* background: #17181b; */
    width: 400px;
    left: -120px !important;
    transform: translateX(0px) !important;
    top: 50px !important;
}

.pairDropdown table {
    background: transparent !important;
    padding: 0px;
    overflow: auto;
    white-space: nowrap;
}

.pairDropdown table th {
    font-size: 12px;
    font-weight: 600;
    color: #777E90;
    text-transform: uppercase;
}

.pairDropdown table thead th {
    background: #17181b;
}

.pairDropdown table tbody tr.active {
    border: 2px solid;
    border-left-color: #6e727c;
}

.pairDropdown table tbody tr td {
    background: #17181b;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
}


.pairDropdown .offcanvas {
    background: #000 !important;
}

.pairDropdown .offcanvas.show {
    background: #000 !important;
}

.pairDropdown .offcanvas .btn-close {
    filter: invert(5) !important;
}

.btn-close {
    filter: invert(1) !important;
    opacity: 1 !important;
}


.offcanvas table tr th {
    background: transparent;
    color: #fff;
}

.offcanvas table tr td {
    background: transparent;
    color: #fff;
}

.offcanvas table tr th {
    background: transparent;
    color: #fff;
}

.offcanvas table tr td {
    background: transparent;
    color: #fff;
}



@media only screen and (max-width: 1440px) {
    .limitmarket_tabs .nav-item button.active {
        padding-left: 20px;
        padding-right: 20px;
    }

    .limitmarket_tabs .nav-item button {
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media only screen and (max-width: 1399px) {
    .limitmarket_tabs .nav-item button.active {
        padding-left: 15px;
        padding-right: 15px;
    }

    .limitmarket_tabs .nav-item button {
        padding-left: 15px;
        padding-right: 15px;
    }

    .buyselltabsBox .btn.btn-custombtn5 {
        font-size: 12px;
    }

    .buyselltabsBox .btn.btn-custombtn6 {
        font-size: 12px;
    }
}





@media only screen and (max-width: 1200px) {
    .limitmarket_tabs .nav-item button.active {
        padding-left: 30px;
        padding-right: 30px;
    }

    .limitmarket_tabs .nav-item button {
        padding-left: 30px;
        padding-right: 30px;
    }
}



@media only screen and (max-width: 1199px) {
    .limitmarket_tabs .nav-item button.active {
        padding-left: 20px;
        padding-right: 20px;
    }

    .limitmarket_tabs .nav-item button {
        padding-left: 20px;
        padding-right: 20px;
    }
}



@media only screen and (max-width: 1024px) {
    .limitmarket_tabs .nav-item button.active {
        padding-left: 20px;
        padding-right: 20px;
    }

    .limitmarket_tabs .nav-item button {
        padding-left: 20px;
        padding-right: 20px;
    }



}


@media only screen and (max-width: 991px) {}



@media only screen and (max-width: 768px) {

    .limitmarket_tabs {
        display: flex;
        justify-content: normal;
    }

    .limitmarket_tabs .nav-item button {
        margin-right: 15px;
    }


}


@media only screen and (max-width: 767px) {
    .limitmarket_tabs .nav-item button.active {
        padding-left: 15px;
        padding-right: 15px;
    }

    .limitmarket_tabs .nav-item button {
        padding-left: 15px;
        padding-right: 15px;
    }

    .buyselltabsBox {
        height: auto;
    }



    .pairtableBox.assetstable {
        height: auto;
        overflow: auto;
    }










}

@media only screen and (max-width: 480px) {

    .order_tabs .nav-item button.active {
        padding-left: 10px;
        padding-right: 10px;
    }

    .order_tabs .nav-item button {
        padding-left: 10px;
        padding-right: 10px;
    }






}

@media only screen and (max-width: 425px) {
    .pairDropdown .dropdown-menu.show {
        width: 350px;
        left: -120px !important;
        transform: translateX(0px) !important;
        top: 50px !important;
        display: block;
        margin: 0px;
        padding: 10px;
        background: #17181b;
    }
}

@media only screen and (max-width: 320px) {}