.goog-te-gadget {
    color: transparent !important;
}

.goog-te-gadget .VIpgJd-ZVi9od-l4eHX-hSRGPd {
    display: none;
}


.goog-te-gadget .goog-te-combo {
    margin: 0px 0 !important;
    outline: none;
    cursor: pointer;
    background: #141416;
    height: 30px;
    padding: 0 2px;
    font-size: 14px;
    appearance: none;
    color: #fff;
    border: none;
    border-radius: 50px;
    font-family: 'DM Sans', sans-serif;
}

#google_translate_element {
    max-height: 40px;
}

.goog-te-gadget {
    font-size: 0px !important;
}

.languaDiv {
    margin: 0px 0 !important;
    border: 1px solid #333437;
    outline: none;
    cursor: pointer;
    margin-right: 20px !important;
    background: none;
    box-shadow: 0 0 0 2px #E6E8EC inset;
    color: #fff;
    text-decoration: none;
    height: 45px;
    border-radius: 30px;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

/*--------------hide google translator ----------------*/

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none !important;
}

body {
    top: 0px !important;
}

.goog-te-spinner-pos {
    display: none !important;
}

iframe#\:1\.container {
    visibility: hidden !important;
    display: none !important;
    z-index: 00000000 !important;
}