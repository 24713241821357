.profile_sec {
    width: 100%;
    height: auto;
}

.headMenubox {
    border-bottom: 1px solid #23262f;
}

.headtopMenu {
    display: flex;
    align-items: center;
}

.headtopMenu p {
    color: #777E90;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    margin-bottom: 0px;
}

.headtopMenu h6 {
    color: #FCFCFD;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
}

.ledtchevIcon {
    font-size: 20px;
    color: #FCFCFD;
    margin: 0px 15px;
}

.prosideMenu ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.prosideMenu ul li {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid transparent;
}

.prosideMenu ul li.active {
    border-bottom: 1px solid #23262f;
    padding-bottom: 20px;
    margin-top: 20px;
}

.prosideMenu ul li:first-child {
    margin-top: 0px;
}

.prosideMenu ul li:hover {
    border-bottom: 1px solid #23262f;
    transition: all .5s ease;
}

.prosideMenu ul li:hover a {
    color: #FCFCFD;
}

.prosideMenu ul li:hover a span svg {
    color: #FCFCFD;
}

.prosideMenu ul li a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: #777E90;
    font-family: 'DM Sans', sans-serif;
}

.prosideMenu ul li a span {
    margin-right: 20px;
}

.prosideMenu ul li:hover img {
    filter: brightness(20.5);
}

.prosideMenu ul li a span svg {
    color: #777E90;
    font-size: 22px;
}

.prosideMenu ul li.active a {
    color: #FCFCFD;
}

.prosideMenu ul li.active a span svg {
    color: #FCFCFD;
}

.paterText h4 {
    color: #FCFCFD;
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins;
}

.paterBox {
    display: flex;
    justify-content: space-between;
}

.paterBoxleft {
    display: flex;
    align-items: center;
}

.userImg {
    width: 120px;
    border-radius: 50%;
}

.profileContent {
    width: 100%;
    height: auto;
    background: #18191D;
    border-radius: 15px;
}


.paterBoxright .dropdown-toggle {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 40px;
    font-weight: 700;
    transition: all .2s;
    background: #23262F;
    padding: 5px 15px;
    color: #FCFCFD;
    border-radius: 24px;
}

.paterBoxright .dropdown-toggle:hover {
    background-color: #23262f;
    border-color: #23262f;
    color: #FCFCFD;
}

.paterBoxright .dropdown-toggle.show.btn {
    border-color: #23262f;
    background-color: #23262f;
    color: #FCFCFD;
}

.paterBoxright .dropdown-toggle:active {
    background-color: #23262f !important;
    color: #FCFCFD !important;
    border-color: #23262f !important;
}

.paterBoxright .dropdown-menu.show {
    display: block;
    min-width: 260px;
    margin-top: 5px;
}

.paterBoxright .dropdown-toggle::after {
    border: none;
}

.paterBoxright .chevronicon {
    font-size: 15px;
    margin-bottom: 2px;
    margin-left: 150px;
}

.paterBoxright .dropdown-toggle::after {
    border: none;
    display: none;
}

.usaIcon {
    width: 25px;
    object-fit: contain;
    margin-right: 15px;
}

.detailBlock {
    display: flex;
    justify-content: space-between;
}

.detailBlockleft ul,
.detailBlockright ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.detailBlockleft ul li {
    margin-bottom: 30px;
}

.detailBlockright ul li {
    margin-bottom: 25px;
}

.detailBlockleft ul li h6 {
    font-size: 14px;
    font-weight: 500;
    color: #FCFCFD;
}

.detailBlockright ul li h6 {
    font-size: 14px;
    font-weight: 500;
    color: #777E90;
    text-align: right;
}

.detailBlockright ul li .form-switch {
    float: right;
}

.detailBlockright ul li:first-child {
    display: flex;
    align-items: center;
    justify-content: right;
}

.copyIcons {
    color: #777E90;
    font-size: 16px;
}

.profileqrcode h2 {
    color: #FCFCFD;
    font-size: 40px;
    font-weight: 500;
}

.profileqrcode h2 span {
    color: #58BD7D;
    font-size: 40px;
    font-weight: 500;
}

.qrcodeImg {
    width: 150px;
}

.profileqrcode p {
    font-family: 'Poppins';
}

.uploadImgbox {
    display: flex;
}


.uploadbox1 {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    background: #18191d;
    justify-content: center;
    border: 2px solid #3772FF;
    border-style: dashed;
    padding: 20px;
    border-radius: 15px;
    flex-direction: column;
}

span.alert_validation {
    color: #ff0000;
}

.uploadbox1 span {
    color: #FCFCFD;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}


.uploadImg1 {
    width: 120px;
}

.profile {
    position: relative;
}

.profile input {
    width: 130px;
    position: absolute;
    top: 150px;
    left: 150px;
}

.uploader__file_input_label svg {
    opacity: 0;
}

.uploader__btn {
    display: none;
}

/* .uploader__placeholder {
    border-radius: 15px;
} */

.uploader__placeholder {
    border-radius: 15px;
    position: relative;
    /* background: url('../../../public/assets/images/uploadImg1.png') !important; */
    overflow: hidden;
    min-height: 100px;
    min-width: 100px;
    background-repeat: no-repeat !important;
}

.uploadImgbox span {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    color: #B1B5C3;
    font-family: 'Poppins';
}

.deposit_sec h2 {
    color: #FCFCFD;
    font-size: 40px;
    font-weight: 500;
}

.deposit_sec p {
    color: #777E90;
    font-weight: 400;
    font-family: 'Poppins';
    font-size: 14px;
}

.depositqrImg {
    width: 200px;
}

.deposit_sec h3 {
    color: #FCFCFD;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}

.uploadImgbox.deposit .uploadbox1 {
    width: 100%;
    height: 250px;
}

.uploadImgbox.deposit {
    display: block;
}


.verifyFiled input {
    height: 100px;
    background: #23262F;
    border-color: #23262F;
    border-radius: 15px;
    color: #FCFCFD;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    text-align: center;
    padding: 0;
}

.verifyFiled input:focus {
    background: transparent;
    border-color: #23262F;
    box-shadow: none;
    border: 1px solid #23262f;
    color: #FCFCFD;
}

.verifyFiled input::placeholder {
    color: #FCFCFD;
}


.backupBox ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.backupBox ul li {
    margin-bottom: 20px;
}

.backupBox ul li h4 {
    color: #FCFCFD;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.eyeIcons {
    color: #777E90;
}

.enable2fText h4 {
    color: #FCFCFD;
    font-size: 24px;
    font-weight: 600;
}

.enable2fText p {
    color: #B1B5C3;
    font-size: 14px;
    font-weight: 400;
}

.enable2fText span {
    color: #FCFCFD;
    font-size: 16px;
    font-weight: 500;
}

.enable2fText svg {
    font-size: 22px;
    margin-right: 10px;
}

.downloadappBox {
    width: 100%;
    height: auto;
    border-radius: 16px;
    background: #23262F;
}

.downloadInner {
    width: 300px;
    border-radius: 32px 32px 0px 0px;
    background: #353945;
    margin: 0px auto;
    padding: 40px;
}

.downloadappBox .uploadbox1 {
    background: transparent;
}

.withdraw_sec h2 {
    color: #fcfcfd;
    font-size: 40px;
    font-weight: 500;
}

.paterBoxright.bankdrop button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paterBoxright.bankdrop .dropdown-menu.show {
    min-width: 100%;
}

.banknameImg {
    width: 70px;
}

.balanceDiv {
    border-radius: 4px;
    background: #353945;
    padding: 15px 30px;
}

.balanceDiv span {
    color: #777E90;
    font-size: 14px;
    font-weight: 400;
}

.balanceDiv h6 {
    color: #FCFCFD;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}

.freeInputbox h6 {
    color: #777E90;
    font-size: 14px;
    font-weight: 500;
}

.freeInputbox .input-group-text {
    background: transparent;
    border: none;
    color: #FCFCFD;
    font-size: 14px;
    font-weight: 500;
}

.freeInputbox input {
    background: transparent;
    border: none;
    color: #777E90;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
}

.freeInputbox input:focus {
    box-shadow: none;
    background: transparent;
    color: #777E90;
}

.freeInputbox input::placeholder {
    color: #777E90;
}


.refferral_sec h2 {
    color: #fcfcfd;
    font-size: 40px;
    font-weight: 500;
}

.tatalReward h6 {
    color: #E6E8EC;
    font-size: 14px;
    font-weight: 400;
}

.tatalReward p {
    color: #777E90;
    font-size: 12px;
    font-weight: 400;
}

.inviteDiv {
    border-radius: 16px;
    background: #23262F;
    padding: 15px 30px;
}

.inviteDiv h3 {
    color: #FCFCFD;
    font-size: 24px;
    font-weight: 600;
}

.inviteDiv .field__label {
    color: #FCFCFD;
}

.inviteDiv .field__input {
    background: #353945;
    color: #FCFCFD;
}

.inviteDiv .field__input::placeholder {
    color: #FCFCFD;
}


.prosideMenu ul li.active .promenuIcons {
    filter: brightness(30.5);
}

.deposit_sec h4 {
    color: #FCFCFD;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}


.copyicons {
    color: #fff;
}
























@media only screen and (max-width: 1399px) {
    .verifyFiled input {
        height: 120px;
        padding: 0;
    }
}

@media only screen and (max-width: 1199px) {
    .paterBoxleft {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .paterBox {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .paterBoxright {
        margin-top: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 991px) {
    .paterBox {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
}



@media only screen and (max-width: 768px) {
    .verifyFiled input {
        height: 70px;
        border-radius: 8px;
    }

    .paterBox {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .paterBoxleft {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .headMenubox h1 {
        font-size: 20px;
    }

    .paterBoxright {
        margin-top: 20px;
        text-align: center;
    }

    .deposit_sec h2 {
        text-align: center;
    }

    .deposit_sec p {
        text-align: center;
    }

    .deposit_sec h3 {
        /* text-align: center; */
    }

    .withdraw_sec h2 {
        text-align: center;
    }

    .deposit_sec h4 {
        text-align: center;
    }


}



@media only screen and (max-width: 767px) {

    .verifyFiled input {
        height: 70px;
        border-radius: 8px;
    }

    .paterBox {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .paterBoxleft {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .headMenubox h1 {
        font-size: 20px;
    }

    .paterBoxright {
        margin-top: 20px;
        text-align: center;
    }

    .uploadImgbox {
        display: flex;
        flex-direction: column;
    }

    .uploadbox1 {
        width: 100%;
        height: 200px;
    }

    .profileqrcode p {
        font-size: 12px;
        text-align: center;
    }

    .prosideMenu .btn.btn-custombtn1 {
        font-size: 14px;
    }

    .prosideMenu .btn.btn-custombtn2 {
        font-size: 14px;
    }

    .profileqrcode h2 {
        text-align: center;
    }

    .prosideMenu {
        border-top: 1px solid #23262f;
        border-bottom: 1px solid #23262f;
        padding: 10px 0px;
    }


    .profileDropbox .dropdown-toggle {
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        line-height: 35px;
        font-weight: 700;
        transition: all .2s;
        background: #23262F;
        padding: 5px 15px;
        color: #FCFCFD;
        border-radius: 24px;
        width: 100%;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #23262f;
    }

    .profileDropbox .dropdown-toggle:hover {
        background-color: #23262f;
        border-bottom: 1px solid #23262f;
        color: #FCFCFD;
    }


    .profileDropbox .dropdown-toggle.show.btn {
        border-color: #23262F;
        border-bottom: 1px solid #23262f;
        color: #FCFCFD;
        background: #23262F;
    }

    .profileDropbox .dropdown-toggle:active {
        background-color: #23262F !important;
        color: #FCFCFD !important;
        border-color: #23262F !important;
    }

    .profileDropbox .dropdown-menu.show {
        display: block;
        min-width: 100%;
        margin-top: 5px;
    }

    .profileDropbox .dropdown-toggle::after {
        border: none;
    }

    .profileDropbox .chevronicon {
        font-size: 15px;
        margin-bottom: 2px;
        margin-left: 150px;
    }

    .profileDropbox .dropdown-toggle::after {
        border: none;
        display: none;
    }

    .f-navchevIcon {
        font-size: 22px;
    }

    .profileIcon {
        font-size: 20px;
        margin-right: 10px;
    }

    .pro2faIcon {
        margin-right: 10px;
        filter: brightness(10.5);
    }


    .profile {
        text-align: center;
    }




}

@media only screen and (max-width: 480px) {

    .verifyFiled input {
        height: 35px;
        border-radius: 8px;
        padding: 0px;
    }

}

@media only screen and (max-width: 320px) {

    .verifyFiled input {
        height: 30px;
        border-radius: 5px;
        padding: 0;
    }

}