.supprtchat_sec {
    width: 100%;
    height: auto;
}


.ticket_num h5 {
    color: #FCFCFD;
    font-size: 18px;
    font-weight: 400;
}

.ticket_num span {
    color: #777E90;
    font-size: 18px;
    font-weight: 300;
}

.chatForm {
    background: #17181b;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    box-shadow: 0px 0px 10px #ffffff4d;
}


.msger-chat {
    flex: 1 1;
    overflow-y: auto;
    padding: 10px;
    max-height: 500px;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg {
    flex-direction: row-reverse;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.msg-bubble {
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
    background: #ececec;

}

.msg.right-msg {
    flex-direction: column;
}

.dateTime {
    margin-top: 15px;
    font-size: 14px;
    color: #FCFCFD;
}

.msg.left-msg .msg-bubble {
    background: #ececec;
    border-bottom-left-radius: 0;
}

.msg.right-msg .msg-bubble {
    background: #3772FF;
    border-bottom-right-radius: 0;
}

.msg.right-msg .msg-bubble .msg-text {
    color: #FCFCFD;
}

.msg.left-msg {
    flex-direction: column;
    align-items: flex-start;
}